import React, { FC } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { MegaMenu } from 'components/padi-ui/header/types';
import useClassNames from 'helpers/hooks/useClassNames';

export interface Props {
  megaMenu: MegaMenu;
  megaMenuNavigator?: MegaMenu[];
  onClick: () => void;
  hideHeaderMenu: () => void;
}

const MobileMenuNavButton: FC<Props> = ({ megaMenu, megaMenuNavigator, onClick, hideHeaderMenu }) => {
  const linkClassNames = useClassNames([
    'flex justify-between',
    megaMenuNavigator?.length === 0 ? 'py-12' : megaMenu?.label === 'myAccount' ? 'pb-16' : 'pb-36',
  ]);
  return (
    <div key={megaMenu.label} className="cursor-pointer">
      {megaMenu?.columns && megaMenu?.columns.length > 0 ? (
        <div onClick={onClick} className={linkClassNames}>
          <Typography className="font-bold">{megaMenu.label}</Typography>
          <ChevronRightIcon className="w-20 text-secondary-black" />
        </div>
      ) : (
        <div onClick={hideHeaderMenu} className={linkClassNames}>
          <Link link={megaMenu?.reference} padi-analytics={megaMenu.dataAnalytics}>
            <Typography className="text-primary-black">{megaMenu.label}</Typography>
          </Link>
        </div>
      )}
    </div>
  );
};

export default MobileMenuNavButton;
