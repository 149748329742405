import { useRef, useCallback } from 'react';
import { Cart, LineItem } from 'shared/types/cart';
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  VIEW_CART,
  BEGIN_CHECKOUT,
  DEFAULT_CURRENCY,
  PADI_CHECKOUT_NAME,
  PADI_BRAND,
} from 'helpers/constants/events';

// Helper type for the structure of a tracking item
interface TrackingItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  bundle_name?: string;
  is_bundle: boolean;
  price: number;
  quantity: number;
}

// Safely fetch attribute values with a default value.
const getAttributeValue = (attr: any, defaultValue = '') => {
  if (Array.isArray(attr)) return attr[0]?.label ?? defaultValue;
  else return attr?.label ?? defaultValue;
};

// Create a tracking item from a line item
const createTrackingItem = (lineItem: LineItem, count?: number): TrackingItem => {
  const itemCount = count ?? lineItem.count ?? 1;
  const bundleProducts = lineItem.variant?.attributes?.['products'];
  const isBundle = Array.isArray(bundleProducts) && bundleProducts.length > 1;
  const price = lineItem.price?.centAmount ? lineItem.price?.centAmount / 100 : 0;

  return {
    item_id: lineItem.productId ?? '',
    item_name: lineItem.name ?? '',
    item_brand: PADI_BRAND,
    item_category: '',
    item_category2: '',
    item_category3: '',
    item_category4: '',
    item_category5: isBundle ? lineItem.variant?.sku : '',
    bundle_name: isBundle ? lineItem?.name : '',
    is_bundle: isBundle,
    price: price,
    quantity: itemCount,
  };
};

export const useTrackPageViewCart = () => {
  const lastCartId = useRef<string | undefined>(undefined);

  const trackPageViewCart = useCallback(async (cart: Cart | null) => {
    if (cart && cart.cartId !== lastCartId.current) {
      const items = cart.lineItems?.map((item) => createTrackingItem(item)) ?? [];
      const totalValue = cart.sum?.centAmount ? cart.sum.centAmount / 100 : 0;

      gtag('event', VIEW_CART, {
        padi_checkout_name: PADI_CHECKOUT_NAME,
        currency: cart.sum?.currencyCode ?? DEFAULT_CURRENCY,
        value: totalValue,
        items: items,
      });

      lastCartId.current = cart.cartId;
    }
  }, []);

  return { trackPageViewCart };
};

export const useTrackClickBeginCheckout = () => {
  const lastCartId = useRef<string | undefined>(undefined);

  const trackClickBeginCheckout = useCallback(async (cart: Cart | null) => {
    if (cart && cart.cartId !== lastCartId.current) {
      const items = cart.lineItems?.map((item) => createTrackingItem(item)) ?? [];
      const totalValue = cart.sum?.centAmount ? cart.sum.centAmount / 100 : 0;

      gtag('event', BEGIN_CHECKOUT, {
        padi_checkout_name: PADI_CHECKOUT_NAME,
        currency: cart.sum?.currencyCode ?? DEFAULT_CURRENCY,
        value: totalValue,
        items: items,
      });

      lastCartId.current = cart.cartId;
    }
  }, []);

  return { trackClickBeginCheckout };
};

export const useTrackClickAddToCart = () => {
  const trackClickAddToCart = useCallback(async (lineItem: LineItem, count = 1) => {
    const item = createTrackingItem(lineItem, count);
    const totalValue = count * (lineItem.price?.centAmount ? lineItem.price.centAmount / 100 : 0);

    gtag('event', ADD_TO_CART, {
      padi_checkout_name: PADI_CHECKOUT_NAME,
      currency: lineItem.price?.currencyCode ?? DEFAULT_CURRENCY,
      value: totalValue,
      items: [item],
    });
  }, []);

  return { trackClickAddToCart };
};

export const useTrackClickRemoveFromCart = () => {
  const trackClickRemoveFromCart = useCallback(async (lineItem: LineItem, count = 1) => {
    const item = createTrackingItem(lineItem, count);
    const totalValue = count * (lineItem.price?.centAmount ? lineItem.price.centAmount / 100 : 0);

    gtag('event', REMOVE_FROM_CART, {
      padi_checkout_name: PADI_CHECKOUT_NAME,
      currency: lineItem.price?.currencyCode ?? DEFAULT_CURRENCY,
      value: totalValue,
      items: [item],
    });
  }, []);

  return { trackClickRemoveFromCart };
};

export default useTrackPageViewCart;
