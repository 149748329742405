import Link from 'components/commercetools-ui/atoms/link';
import Image from 'frontastic/lib/image';
import { Reference } from 'types/reference';

export interface MenuCardProps {
  image: {
    title: string;
    media: {
      alt: string;
      file: string;
    };
  };
  title: string;
  titleUrl: Reference;
  titleDataAnalytics?: string;
  body: string;
  links: object;
}

const getLinkStyle = (style: string) => {
  const baseStyle = 'block mt-4 py-2 ';
  switch (style) {
    case 'blue':
      return `${baseStyle} text-14 font-semibold text-padi-blue whitespace-nowrap hover:text-padi-blue-darker`;
    case 'bold':
      return `${baseStyle} text-base font-semibold text-padi-gray-darkest hover:text-padi-blue`;
    default:
      return `${baseStyle} text-14 text-padi-gray-darker hover:text-padi-blue`;
  }
};
const MenuCard: React.FC<MenuCardProps> = ({ image, title, titleUrl, body, links, titleDataAnalytics }) => {
  const imageSrc = image?.media?.file;
  const imageAlt = image?.media?.alt;
  const imageTitle = image?.title;

  return (
    <>
      <div className="relative text-base text-gray-500 sm:text-sm">
        <div className="max-h-[300px] overflow-hidden rounded-none">
          {imageSrc && (
            <Image
              className="mb-16 aspect-video h-auto w-full object-cover object-center"
              src={imageSrc}
              alt={imageAlt}
              title={imageTitle}
            />
          )}
        </div>

        {title && (
          <h2 className="mb-8 text-base font-semibold text-blue-950">
            {titleUrl ? (
              <Link
                link={titleUrl}
                target={titleUrl?.openInNewWindow ? '_blank' : '_self'}
                title={title}
                padi-analytics={titleDataAnalytics}
              >
                {title}
              </Link>
            ) : (
              title
            )}
          </h2>
        )}

        {body && <p className="mb-16 space-y-2 text-sm">{body}</p>}

        <div className="mb-16 space-y-4 text-base text-blue-950">
          {links &&
            Object.values(links).map((item, index) => (
              <div className="flex" key={index}>
                {item.image?.media?.file && (
                  <Image
                    className="mb-8 mr-16 aspect-square h-64 w-64 shrink-0 self-center object-cover object-center"
                    src={item.image?.media?.file}
                    alt={item.image?.media?.alt}
                    title={item.image?.title}
                  />
                )}
                {item.url?.pageFolder?._url || item.url?.link ? (
                  <Link
                    link={item.url?.pageFolder?._url ?? item.url?.link}
                    className={getLinkStyle(item.style)}
                    target={item.url?.openInNewWindow ? '_blank' : '_self'}
                    title={item.label}
                    padi-analytics={item.dataAnalytics}
                  >
                    {item.label}
                  </Link>
                ) : (
                  item.label
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default MenuCard;
