'use client';

import MaintenanceBar, { Props as MaintenanceBarProps } from 'components/commercetools-ui/organisms/maintenance-bar';
import PadiHeader from 'components/padi-ui/header';
import { PadiHeaderProps } from 'components/padi-ui/header/types';
import { useMemo } from 'react';
import { TasticProps } from '../../types';

const PadiHeaderTastic = ({
  data,
  categories,
  searchParams,
  projectConfig,
}: TasticProps<PadiHeaderProps & MaintenanceBarProps>) => {
  const maintenanceData = {
    activateMaintenance: data.activateMaintenance,
    maintenanceText: data.maintenanceText,
  };

  const navLinks = useMemo(() => categories?.filter((category) => category.depth === 0), [categories]);

  return (
    <div id="padi-header-container" className="z-9 w-full">
      {maintenanceData.activateMaintenance && <MaintenanceBar {...maintenanceData} />}
      <PadiHeader
        {...data}
        navLinks={navLinks}
        searchParams={searchParams}
        projectConfig={projectConfig}
        categories={categories}
      />
    </div>
  );
};
export default PadiHeaderTastic;
