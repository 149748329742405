import React, { useEffect, useState } from 'react';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { Popover } from '@headlessui/react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { ReferenceFieldLink } from 'components/padi-ui/header/types';
import { useFormat } from 'helpers/hooks/useFormat';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import { deleteCognitoCookies } from 'helpers/padi/cognito';
import { DeleteSloSession } from 'helpers/padi/slo';
import { ProjectConfig } from 'types/project-config';
import { useAccount } from 'frontastic';

type Props = {
  projectConfig: ProjectConfig;
  userMenu?: ReferenceFieldLink[];
};

const LoggedIn = (props: Props) => {
  const projectConfig = props.projectConfig;
  const userMenu = props.userMenu;
  const router = useRouter();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { logout } = useAccount();
  const { locale } = useParams();
  const pathname = usePathname();
  const { userData } = useGetUserInfo();

  const handleLogout = () => {
    const sub = userData?.sub;
    logout()
      .then(() => {
        if (sub) DeleteSloSession(sub);
      })
      .then(() => deleteCognitoCookies())
      .then(() => {
        Object.keys(localStorage)
          .filter((key) => !key.startsWith('padi'))
          .map((key) => localStorage.removeItem(key));
      })
      .then(() => (pathname.endsWith('courses/') ? window.location.reload() : router.push(`/${locale}/courses`)));
  };

  const padiDomains = projectConfig?.padiDomains;
  const [learningSiteUrl, setlearningSiteUrl] = useState<string>('');
  useEffect(() => {
    setlearningSiteUrl(`${padiDomains?.learning}/account` || '');
  }, [learningSiteUrl, padiDomains?.learning]);

  return (
    <div className="w-235 p-14">
      {userMenu && userMenu.length ? (
        <>
          {userMenu?.map((link, index) => (
            <div key={`link-${index}-${link?.label}`}>
              <Link link={link?.url} padi-analytics={link?.dataAnalytics} className="mt-10 block w-fit">
                <Typography className="text-primary-black hover:underline">{link?.label}</Typography>
              </Link>
            </div>
          ))}
          <div onClick={handleLogout}>
            <div className="mt-10 block w-fit cursor-pointer">
              <Typography className="text-primary-black hover:underline">
                {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <>
          <Popover.Button className="w-full">
            {learningSiteUrl && (
              <Link link={learningSiteUrl} className="mt-10 block w-fit">
                <Typography className="text-primary-black hover:underline">
                  {formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' })}
                </Typography>
              </Link>
            )}
          </Popover.Button>

          <Popover.Button className="mt-32 block w-full pb-10">
            <div onClick={handleLogout} className="w-fit">
              <Typography className="text-16 text-primary-black hover:underline">
                {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
              </Typography>
            </div>
          </Popover.Button>
        </>
      )}
    </div>
  );
};

export default LoggedIn;
