import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

type Props = {
  className?: string;
};

const SearchIcon: React.FC<Props> = ({ className }: Props) => <MagnifyingGlassIcon className={className} />;

export default SearchIcon;
