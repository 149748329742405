import React, { useCallback, useEffect, useState } from 'react';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import CartIcon from 'components/icons/cart';
import SearchIcon from 'components/icons/search';
import WishlistIcon from 'components/icons/wishlist';
import { Link, ReferenceFieldLink } from 'components/padi-ui/header/types';
import Slideout, { MenuState } from 'components/padi-ui/header/utility-section/components/slide-out';
import { useFormat } from 'helpers/hooks/useFormat';
import { ProjectConfig } from 'types/project-config';
import { useCart, useWishlist } from 'frontastic';
import { ImageProps } from 'frontastic/lib/image';
import AccountButton from './components/account-button';

export interface Props {
  emptyCartTitle: string;
  emptyCartSubtitle: string;
  emptyCartImage: ImageProps;
  emptyCartCategories: Link[];
  emptyWishlistTitle: string;
  emptyWishlistSubtitle: string;
  emptyWishlistImage: ImageProps;
  emptyWishlistCategories: Link[];
  userMenu: ReferenceFieldLink[];
  projectConfig: ProjectConfig;
  handleClickOnSearchIcon: (forceState?: boolean) => void;
  isDisplayCartIcon: boolean;
  isDisplayProfileIcon: boolean;
  isDisplaySearchIcon: boolean;
  isDisplayWishlistIcon: boolean;
  isSearchBarOpen: boolean;
}

const UtilitySection: React.FC<Props> = ({
  emptyCartTitle,
  emptyCartSubtitle,
  emptyCartImage,
  emptyCartCategories,
  emptyWishlistTitle,
  emptyWishlistSubtitle,
  emptyWishlistImage,
  emptyWishlistCategories,
  userMenu,
  projectConfig,
  handleClickOnSearchIcon,
  isDisplayCartIcon,
  isDisplayProfileIcon,
  isDisplaySearchIcon,
  isDisplayWishlistIcon,
  isSearchBarOpen,
}) => {
  const { totalItems: totalCartItems } = useCart();
  const { totalItems: totalWishlistItems } = useWishlist();
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  const [isLoading, setIsLoading] = useState(true);
  const [menuState, setMenuState] = useState<MenuState>();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleIconClick = useCallback(
    (icon: string, menuState?: MenuState) => {
      handleClickOnSearchIcon(icon === 'search');
      if (menuState) {
        setIsDrawerOpen(true);
        setMenuState(menuState);
      }
    },
    [handleClickOnSearchIcon],
  );

  const onSearchClicked = useCallback(() => handleIconClick('search'), [handleIconClick]);
  const onWishlistClicked = useCallback(() => handleIconClick('wishlist', 'wishlist'), [handleIconClick]);
  const onCartClicked = useCallback(() => handleIconClick('cart', 'cart'), [handleIconClick]);
  const accountClick = useCallback(() => handleIconClick('account'), [handleIconClick]);

  return (
    <div className="mt-12 flex h-40 items-center">
      <div className="flex justify-between gap-x-14 lg:gap-x-16">
        {isDisplaySearchIcon && (
          <div className="h-40 w-fit lg:block">
            <button
              title={formatCommonMessage({ id: 'search', defaultMessage: 'Search' })}
              className={`relative h-fit cursor-pointer border-secondary-black pb-8 hover:border-b-2 hover:border-padi-blue hover:text-padi-blue ${
                isSearchBarOpen ? 'border-b-2' : ''
              }`}
              onClick={onSearchClicked}
            >
              <SearchIcon className="w-24 text-padi-gray-darkest hover:text-padi-blue" />
            </button>
          </div>
        )}
        {isDisplayProfileIcon && (
          <div className="h-40 w-fit">
            <AccountButton {...{ projectConfig, userMenu, onIconClick: accountClick }} />
          </div>
        )}
        {isDisplayWishlistIcon && (
          <div className="h-40 w-fit">
            <button
              type="button"
              title={formatWishlistMessage({ id: 'wishlist', defaultMessage: 'Wishlist' })}
              className="relative h-fit cursor-pointer border-secondary-black pb-8  hover:border-b-2 hover:border-padi-blue hover:text-padi-blue"
              onClick={onWishlistClicked}
            >
              <WishlistIcon
                totalWishlistItems={totalWishlistItems}
                className="w-24 text-padi-gray-darkest  hover:text-padi-blue"
              />
            </button>
          </div>
        )}
        {isDisplayCartIcon && (
          <div className="h-40 w-fit">
            <button
              type="button"
              title={formatCartMessage({ id: 'myCart', defaultMessage: 'My Cart' })}
              className="h-fit cursor-pointer border-secondary-black pb-8  hover:border-b-2 hover:border-padi-blue hover:text-padi-blue"
              onClick={onCartClicked}
            >
              <CartIcon
                className="w-24 text-padi-gray-darkest hover:text-padi-blue"
                totalCartItems={!isLoading ? totalCartItems : undefined}
                counterClassName="-translate-y-1/4"
              />
            </button>
          </div>
        )}
      </div>

      <Drawer
        isOpen={isDrawerOpen}
        direction="right"
        className="w-[90%] max-w-380 bg-white"
        onClose={() => setIsDrawerOpen(false)}
      >
        <Slideout
          state={menuState}
          onClose={() => setIsDrawerOpen(false)}
          changeState={(newState) => setMenuState(newState)}
          emptyCartTitle={emptyCartTitle}
          emptyCartSubtitle={emptyCartSubtitle}
          emptyCartImage={emptyCartImage}
          emptyCartCategories={emptyCartCategories}
          emptyWishlistTitle={emptyWishlistTitle}
          emptyWishlistSubtitle={emptyWishlistSubtitle}
          emptyWishlistImage={emptyWishlistImage}
          emptyWishlistCategories={emptyWishlistCategories}
        />
      </Drawer>
    </div>
  );
};

export default React.memo(UtilitySection);
