import { Fragment } from 'react';
import NextLink from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export interface MenuFlyoutSimpleProps {
  links: object;
  align: 'left' | 'right';
  textSize?: 'text-xs' | 'text-sm' | 'text-base';
}

const MenuFlyoutSimple: React.FC<MenuFlyoutSimpleProps> = ({ links, align, textSize = 'text-xs' }) => {
  const menuSize = Object.keys(links).length;
  const isSingleMenuItem = menuSize > 1 ? false : true;
  const firstMenuItem = Object.values(links)[0];
  const classNamePopover = `relative text-${align}`;
  const classNamePopoverPanel = `absolute ${align}-0 z-[351] m-0 px-0 text-left`;
  const topLinkClassName = `flex h-full items-center text-gray-900 hover:text-padi-blue ${textSize}
  ${textSize == 'text-xs' ? 'leading-6 p-2 ' : 'mt-10'}`;
  const buttonClassName = `inline-flex items-center gap-x-1 ${textSize} leading-6 text-gray-900
  ${textSize == 'text-xs' ? 'leading-6 p-2 ' : 'mt-10'}`;
  const panelClassName = `w-auto shrink rounded-sm bg-white p-4 ${textSize} leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5
  ${textSize == 'text-xs' ? 'leading-6 p-2 ' : 'mt-10'}`;

  const topLevelMenuItem = () => {
    let topLink = (
      <NextLink
        href={firstMenuItem.url?.pageFolder?._url ?? firstMenuItem.url?.link}
        className={topLinkClassName}
        target={firstMenuItem.url?.openInNewWindow ? '_blank' : '_self'}
        title={firstMenuItem.label}
        padi-analytics={firstMenuItem.dataAnalytics}
      >
        {firstMenuItem.label}
      </NextLink>
    );

    if (!firstMenuItem.url?.pageFolder?._url && !firstMenuItem.url?.link) {
      topLink = <span>{firstMenuItem.label}</span>;
    }

    if (!isSingleMenuItem) {
      return (
        <>
          {topLink}
          <ChevronDownIcon className="h-20 w-20" aria-hidden="true" />
        </>
      );
    } else {
      return <>{topLink}</>;
    }
  };

  return (
    <>
      {!isSingleMenuItem ? (
        <Popover className={classNamePopover}>
          <Popover.Button className={buttonClassName}>{topLevelMenuItem()}</Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className={classNamePopoverPanel}>
              <div className={panelClassName}>
                {Object.values(links)
                  .slice(1)
                  .map((item, index) => (
                    <NextLink
                      key={index}
                      href={item.url?.pageFolder?._url ?? item.url?.link}
                      className="block whitespace-nowrap px-4 py-2 hover:text-padi-blue"
                      target={item.url?.openInNewWindow ? '_blank' : '_self'}
                      title={item.label}
                      padi-analytics={item.dataAnalytics}
                    >
                      {item.label}
                    </NextLink>
                  ))}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      ) : (
        topLevelMenuItem()
      )}
    </>
  );
};

export default MenuFlyoutSimple;
