import { ReactNode } from 'react';
import PadiGeolocationModalButton from 'components/padi-geolocation-modal/geolocation-modal-button';
import MenuFlyoutSimple from 'components/padi-ui/menu/flyout-simple';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

export interface HeaderTopbarProps {
  menus: object;
  currencySelector?: ReactNode;
}

const HeaderTopbar: React.FC<HeaderTopbarProps> = ({ menus }) => {
  const [isDesktopSizeOrLarger] = useMediaQuery(desktop);
  return (
    <>
      <div id="padi-header-topbar">
        {isDesktopSizeOrLarger ? (
          <div className="w-full border-b-[1.5px] border-padi-gray-light">
            <div className="mx-auto flex h-44 max-w-7xl items-center justify-end gap-10 px-24 lg:px-32">
              {Object.values(menus).map((menu, index) => (
                <MenuFlyoutSimple key={index} links={menu?.links} align="right" />
              ))}

              <div className="flex h-full w-auto items-center justify-end pr-0">
                {isDesktopSizeOrLarger && <PadiGeolocationModalButton></PadiGeolocationModalButton>}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="my-10 flex h-full w-auto items-center border-y-[1.5px] border-neutral-400 py-10">
              <PadiGeolocationModalButton></PadiGeolocationModalButton>
            </div>

            <div className="flex h-44 flex-col justify-start">
              {Object.values(menus).map((menu, index) => (
                <MenuFlyoutSimple key={index} links={menu?.links} align="left" textSize="text-base" />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderTopbar;
